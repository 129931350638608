<template>
    <b-card no-body>
        <b-modal id="update" :title="editing ? 'Güncelle' : 'Oluştur'" no-close-on-esc no-close-on-backdrop>
            <alis-irsaliye-ek-alan-update v-model="alisIrsaliye"></alis-irsaliye-ek-alan-update>
            <template #modal-footer><v-executer :loading="loading"  :action="update"></v-executer></template>
        </b-modal>

        <v-table    ref="table"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server"></v-table>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { NetsisApi } from '@/api/netsis'
import AlisIrsaliyeEkAlanUpdate from './components/AlisIrsaliyeEkAlanUpdate.vue'
export default {
    components:{
        BCard,
        AlisIrsaliyeEkAlanUpdate,
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Fiş No',key:'Fisno',sortable:true, thClass:'w-10'},
                {name:'İrsaliye No',key:'Irsaliye_No',sortable:true, thClass:'w-10'},
                {name:'İrsaliye Tarih',key:'Irsaliye_Tarih',sortable:true, thClass:'w-10'},
                {name:'Açıklama',key:'Sthar_Aciklama',sortable:true, thClass:'w-20'},
                {name:'Cari Kod',key:'Sthar_Carikod',sortable:true, thClass:'w-10'},
                {name:'Miktar',key:'Sthar_Gcmik',sortable:true, thClass:'w-10'},
                {name:'Stok Kodu',key:'Stok_Kodu',sortable:true, thClass:'w-10'},
                {name:'Taşıyıcı Cari Kod',key:'Ekalan',sortable:true, thClass:'w-10'},
                {name:'Araç Tipi',key:'Ekalan1',sortable:true, thClass:'w-10'},
            ],
            buttons:[
                { text:"Düzenle",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.edit },
            ],
            alisIrsaliye: {
                IncKeyNo: null,
                IrsaliyeNo: null,
                AracTipi: null,
                TasiyiciCariKod: null
            }
        }
    },
    methods:{
        clearForm(){
            this.editing = false
            this.alisIrsaliye.IncKeyNo = null
            this.alisIrsaliye.AracTipi = null
            this.alisIrsaliye.TasiyiciCariKod = null
        },
        provider(data){
            return NetsisApi.GetAlisIrsaliye(data)
        },
        edit(item){
            this.clearForm()
            this.editing = true
            this.alisIrsaliye.IncKeyNo = item.IncKeyNo
            this.alisIrsaliye.AracTipi = item.Ekalan1
            this.alisIrsaliye.TasiyiciCariKod = item.Ekalan
            this.$showModal('update')
        },
        update(){
            this.loading = true
            NetsisApi.UpdateAlisIrsaliyeEkAlans(this.alisIrsaliye).then(res=>{
                this.$result(res)
                this.loading = false
                if(res.data.Success){
                    this.$refs.table.refresh()
                    this.$hideModal('update')
                }
            }).catch(err=>{
                this.loading = false
                this.$result(null,err)
            })
        },
    }
}
</script>

<style>

</style>