<template>
    <div class="row">
        <div class="col-md-12 mb-1">
            <label class="form-label">Taşıyıcı Cari</label>
            <v-select-list :loading="cariLoading" :options="cariList"  v-on:search="getCariList" v-model="cari" placeholder="Cari" label="CARI_ISIM"></v-select-list>
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Araç Tipi</label>
            <v-select-list :options="vehicleTypes" v-model="vehicleType" placeholder="Araç Tipi" label="Name"></v-select-list>
        </div>
    </div>
</template>

<script>
import { NetsisApi } from '@/api/netsis'
import {VehicleTypeApi} from '@/api/vehicle-type'
export default {
    props:{
        value:Object
    },
    data(){
        return{
           cariList:[],
           cariLoading:false,
           vehicleTypes:[]
        }
    },
    mounted(){
        this.getVehicleTypes()
        this.getCariList(this.value.TasiyiciCariKod)
    },
    methods:{
        getVehicleTypes(){
            VehicleTypeApi.List().then(res => {
                this.vehicleTypes = res.data.Data
            }).catch(err=> {
                console.error(err)
            })
        },
        getCariList(data){
            if(data){
                this.cariLoading = true
                NetsisApi.CariList(data).then(res => {
                    this.cariList = res.data.Data
                    this.cariLoading = false
                }).catch(err=> {
                    console.error(err)
                    this.cariLoading = false
                })
            }
            
        },
    },
    computed:{
        cari:{
            get:function(){
                return this.cariList.find(s=>s.CARI_KOD==this.value.TasiyiciCariKod)
            },
            set:function(value){
                this.value.TasiyiciCariKod = value?.CARI_KOD || null
            }
        },
        vehicleType:{
            get:function(){
                return this.vehicleTypes.find(s=>s.Code==this.value.AracTipi)
            },
            set:function(value){
                this.value.AracTipi = value?.Code || null
            }
        },
    }
}
</script>

<style>

</style>